import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    v3Level7DQCode
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api


const columns = [
    {name: 'DQCO#', key: 4, json_key: 'dqco_no', data_key: 'dqco_no'},
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
    {name: 'Description', key: 3, json_key: 'description', data_key: 'description'},
    {name: 'DQC#', key: 5, json_key: 'dqc_no', data_key: 'dqc_no'},
    {name: 'DQM#', key: 6, json_key: 'dqm_no', data_key: 'dqm_no'},
    {name: 'Stakeholder#', key: 7, json_key: 'stakeholder_no', data_key: 'stakeholder_no'},
    {name: 'DQA#', key: 8, json_key: 'dqa_no', data_key: 'dqa_no'},
]


const
    tableCols = [
    {
        label: 'DQCO#',
        field: 'dqco_no', width: 150
    },
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'Description',
        field: 'description', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Level7DQCodePage() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level7 DQCode'}
            modal_title={'Level7 DQCode - Data Preview'}
            sheet_name={'Level7 (DQCode)'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={v3Level7DQCode.listData}
            postAPI={v3Level7DQCode.postData}
            deleteAPI={v3Level7DQCode.deleteEntity}
            updateAPI={v3Level7DQCode.updateEntity}
            preRemoveAPI={v3Level7DQCode.preRemoveCheck}
        />
    );
}
